<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Müşteri Kullanıcıları</b-card-title>
        <b-button
          variant="primary"
          @click="addUser"
        >
          Kullanıcı Ekle
        </b-button>
      </b-card-header>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(name)="data">
          <div class="d-flex justify-content-start align-items-center">
            <b-button
              variant="flat-primary"
              class="btn-icon mr-1"
              pill
              @click="getUserData(data.item.id)"
            >
              <FeatherIcon
                icon="EditIcon"
              />
            </b-button>
            <div>
              {{ data.item.name }}
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <FeatherIcon
            :icon="data.item.status === '1'? 'CheckIcon' : 'XIcon'"
            :class="data.item.status === '1'? 'text-success' : 'text-danger'"
          />
        </template>
        <template #cell(phone)="data">
          <div>{{ data.item.phone }}</div>
          <div class="text-primary font-small-2">
            {{ data.item.email }}
          </div>
        </template>
        <template #cell(logined)="data">
          {{ data.item.logined? moment(data.item.logined).format('llll') : '-' }}
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="userFormModal"
      centered
      title="Kullanıcı Hesabı"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
    >
      <customer-user-form :id-customers="$route.params.id" />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BTable, BModal, BButton,
} from 'bootstrap-vue'
import CustomerUserForm from '@/views/Admin/Customers/CustomerUserForm.vue'

export default {
  name: 'CustomerUsers',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BModal,
    BButton,
    CustomerUserForm,
  },
  data() {
    return {
      userFormModal: false,
      fields: [
        {
          key: 'name',
          label: 'ADI SOYADI',
        },
        {
          key: 'phone',
          label: 'TELEFON',
        },
        {
          key: 'status',
          label: 'DURUM',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'logined',
          label: 'SON OTURUM',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customerUsers/dataList']
    },
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    addUser() {
      this.$store.commit('customerUsers/RESET_DATA_ITEM')
      this.userFormModal = true
    },
    getData() {
      this.$store.dispatch('customerUsers/getDataList', {
        where: {
          'customer_users.id_customers': this.$route.params.id,
        },
      })
    },
    getUserData(id) {
      this.$store.dispatch('customerUsers/getDataItem', id)
        .then(response => {
          if (response.id) {
            this.userFormModal = true
          }
        })
    },
  },
}
</script>
