<template>
  <b-row no-gutters>
    <b-col cols="auto">
      <b-form-group
        label="Ülke Kodu"
        label-for="phone_region_code"
      >
        <div class="d-flex">
          <vue-country-code
            :preferred-countries="['tr', 'de', 'az']"
            class="form-control"
            :default-country="dataItem.phone_region_iso2"
            @onSelect="onSelect"
          />
          <b-form-input
            id="phone_region_code"
            v-model="dataItem.phone_region_code"
            type="number"
            class="bg-light phone_region_code"
            readonly
          />
        </div>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Telefon"
        label-for="phone"
      >
        <cleave
          id="phone"
          v-model="dataItem.phone"
          class="form-control phone_number"
          :raw="true"
          :options="options.phone"
          placeholder="Telefon"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import VueCountryCode from 'vue-country-code'

export default {
  name: 'UserPhone',
  components: {
    BFormGroup,
    BFormInput,
    Cleave,
    BRow,
    BCol,
    VueCountryCode,
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerUsers/dataItem']
    },
  },
  methods: {
    onSelect({ iso2, dialCode }) {
      this.dataItem.phone_region_iso2 = iso2
      this.dataItem.phone_region_code = dialCode
    },
  },
}
</script>
